module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-loadable-components-ssr/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://06d51080bdaa4fba882014b513aef5e0@o1192228.ingest.sentry.io/6313811","environment":"IO Navigator STAGING","release":"2915c7053267b81f46197d84bc4341c2fbfeb1cb","sampleRate":1,"ignoreErrors":["@math.gl/web-mercator: assertion failed","Fetch is aborted","NS_ERROR_FILE_NO_DEVICE_SPACE","ResizeObserver loop limit exceeded","ResizeObserver loop completed with undelivered notifications."]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
